import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { connect } from "react-redux";
import { appRedirect } from "../redux/app/actions";
import {
	PageContentWrapper,
	PageContent,
	PageTwoColWrapper,
} from "../assets/styles/pageCommon";
import { colors } from "../assets/styles/vars";
import PageHeader from "../components/Common/PageHeader";
import ModulesList from "../components/Common/ModulesList";
import { userInformationCompleted } from "../utils/usuario";

const MiCuentaPage = ({ usuario, authResolved, loggedIn, appRedirect }) => {
	const staticAccountMoudules = [
		{
			moduleUrl: !usuario?.premium ? null : "/alerts",
			icon: "/img/icons/icon-bell.svg",
			title: "Alertas de nevadas",
			text: "Configura alertas en tus estaciones favoritas y recibe los partes más frescos  en tu email.",
			blocked: true,
			tooltipIcon: !usuario?.premium ? "/img/icons/icon-lock.svg" : null,
			tooltipIconColor: !usuario?.premium ? "green" : null,
			tooltipText: !usuario?.premium ? "Tienes que estar subscripto." : null,
		},
		{
			moduleUrl: "/favs",
			icon: "/img/icons/icon-heart.svg",
			title: "Favoritos",
			text: "Ahorra tiempo en tus búsquedas, aquí podrás ver todo lo que te interesa en una misma página.",
		},
		{
			// moduleUrl: !usuario?.premium ? null : "/courses",
			icon: "/img/icons/icon-graduate-hat.svg",
			title: "Cursos <span>(Próximamente)</span>",
			text: "Analiza como un pro, optimiza tu tiempo y dinero con nuestro curso de lectura de partes y uso de herramientas.",
			blocked: true,
			tooltipIcon: !usuario?.premium ? "/img/icons/icon-lock.svg" : null,
			tooltipIconColor: !usuario?.premium ? "green" : null,
			tooltipText: !usuario?.premium ? "Tienes que estar subscripto." : null,
		},
		{
			moduleUrl: "/subscription",
			icon: "/img/icons/icon-credit-card.svg",
			title: "Suscripción",
			text: "Administra tu suscripción y preferencias de pago.",
		},
		{
			moduleUrl: "/help",
			icon: "/img/icons/icon-info.svg",
			title: "Ayuda",
			text: "¿Tienes dudas?\n" + "Aquí seguro podemos ayudarte.",
		},
	];
	const [accountModulesItems, setAccountModulesItems] = useState([]);

	useEffect(() => {
		if (authResolved && !loggedIn) appRedirect({ to: "/" });
	}, [authResolved, loggedIn]);

	useEffect(() => {
		// console.log("usuario", usuario);
		if (usuario !== null) {
			setAccountModulesItems([
				{
					moduleUrl: "/information",
					icon: "/img/icons/icon-user.svg",
					title: "Info personal",
					text: "Completa tu perfil para obetener la mejor experiencia en Fresh Snow.",
					tooltipIcon:
						!userInformationCompleted(usuario) &&
						"/img/icons/icon-info.svg",
					tooltipIconColor: !userInformationCompleted(usuario) && "red",
					tooltipText: userInformationCompleted(usuario)
						? ""
						: "Tienes datos sin completar",
				},
				...staticAccountMoudules,
			]);
		}
	}, [usuario]);

	return (
		<Layout>
			<Seo title="Mi Cuenta" />
			{authResolved && loggedIn && (
				<section>
					<PageHeader title={`¡Hola, ${usuario?.nombre}!`} />

					<PageContentWrapper bgColor={colors.grayF2}>
						<PageContent width="1050px">
							<PageTwoColWrapper>
								<div className="pageCol leftCol">
									<p>
										Este es tu panel de control 🕹, disfruta de una
										experiencia digital en la nieve con todos los
										servicios que hemos pensado para tí.
									</p>
								</div>

								<div className="pageCol fullCol">
									<ModulesList items={accountModulesItems} />
								</div>
							</PageTwoColWrapper>
						</PageContent>
					</PageContentWrapper>
				</section>
			)}
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		authResolved: state.auth.resolved,
		loggedIn: state.auth.loggedIn,
		usuario: state.auth.usuario,
	};
};

const mapDispatchToProps = {
	appRedirect,
};

export default connect(mapStateToProps, mapDispatchToProps)(MiCuentaPage);
